import { createContext, useState } from "react"


export const AuthContext = createContext(null);

export default function AuthProvider({ children }) {

    const [userNew, setUserNew] = useState(null);
    let [isAlreadyExist, setIsAlreadyExist] = useState(false);

    const signIn = (user, callBack) => {
        console.log(user);
        setUserNew(user);
        callBack();
    };
    const signOut = (callBack) => {
        console.log(6);
        setUserNew(null);
        callBack();
    };

    const changeStatusAlreadyExist = (status) => {
        setIsAlreadyExist(status);
    }



    const value = { userNew, signIn, signOut, isAlreadyExist, changeStatusAlreadyExist }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}
