import { Navigate, useLocation } from "react-router-dom"
import UseAuth from "./UseAuth";


export default function PrivateRoute({ children }) {

    const location = useLocation();

    const { userNew } = UseAuth();



    if (!userNew) {
        console.log(4);

        return <Navigate to="/login" state={{ from: location }} />
    }

    console.log(5);


    return children


}

// auth ? <Outlet /> : <Navigate to="/login" />