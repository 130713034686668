import '../App.css';
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import UseAuth from '../routes/UseAuth';

let link = true ? "https://artvimount.tech" : "http://localhost:3001"

const socket = io(link);

console.log(socket);

socket.on("connect_error", (err) => {
  // the reason of the error, for example "xhr poll error"
  console.log(err.message);

  // some additional description, for example the status code of the initial HTTP response
  console.log(err.description);

  // some additional context, for example the XMLHttpRequest object
  console.log(err.context);
})


function ChatPage() {

  let [message, setMessage] = useState("");

  const [chatList, setChatList] = useState([]);
  const [listOfUsers, setListOfUsers] = useState([]);
  const { userNew, signOut, isAlreadyExist, changeStatusAlreadyExist } = UseAuth()
  const navigate = useNavigate()

  const letsChat = () => {
    socket.emit("newUser", {
      user: userNew,
      socketID: socket.id
    });
    socket.on("userAlreadyExist", () => {
      changeStatusAlreadyExist(true)
      signOut(() => navigate("/login"))
    })

  }

  useEffect(() => {
    letsChat()
  }, [])




  useEffect(() => {
    socket.on("responce", (data) => {
      setChatList([...chatList, data])
    })
  }, [socket, chatList])

  useEffect(() => {
    socket.on("newUserCreated", (data) => {
      setListOfUsers(data)
    })
  }, [socket, listOfUsers])



  const out = () => {
    socket.emit("disconnecte", {
      user: userNew,
    });

    signOut(() => navigate("/login"));
  }

  const sendMessage = () => {
    console.log({
      user: userNew,
      message
    })

    if (message.trim() && userNew) {
      socket.emit("message", {
        user: userNew,
        text: message,
        id: `${socket.id}`,
        socketID: socket.id
      })
    }

  }

  // const check = async () => {
  //   const request = await fetch("https://artvimount.tech/socket.io/?EIO=4&transport=polling", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept": "application/json"
  //     },
  //     method: "GET"
  //   })

  // }

  return (
    <div className="ChatPage">
      <div className='listOfUsers'>

        {listOfUsers.map((user, key) => {
          console.log(user)
          return user.user == userNew ?
            <ul key={key + Math.random()}><b>ВЫ</b></ul> :
            <ul key={key + Math.random()}><b>{user.user}</b></ul>
        })}
      </div>
      <div className='chat'>
        <div className='chat_out'>
          <button onClick={() => { out() }}>Exit</button>
        </div>
        <div className='chat_messages'>
          {chatList.map((chatMessage, key) => {
            return chatMessage.user == userNew ?
              <ul key={key + Math.random()}><b>ВЫ: </b>{chatMessage.text}</ul> :
              <ul key={key + Math.random()}><b>{chatMessage.user}: </b>{chatMessage.text}</ul>
          })}
        </div>
        <div className='chat_tools'>

          <input value={message} onChange={(e) => setMessage(e.target.value)} />
          <p>{message}</p>
          <button onClick={() => { sendMessage() }}>Send</button>
        </div>
      </div>

    </div>
  )
}

export default ChatPage;
