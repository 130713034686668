import { useLocation, useNavigate } from 'react-router-dom';
import UseAuth from '../../routes/UseAuth';
import "./css.css"

function LoginPage() {

  const navigate = useNavigate();
  const location = useLocation();

  const { signIn, isAlreadyExist } = UseAuth();

  const fromPage = location.state?.from?.pathname || "/"


  console.log(4);
  const handler = (e) => {
    e.preventDefault();
    const form = e.target;
    const user = form.username.value;
    signIn(user, () => { navigate(fromPage, { replace: true }) })
  }

  return (
    <div className="loginPage">

      <form onSubmit={handler} className='mainForm'>


        <p className='form_description'>Your NIC:</p>
        <input className='form_input' type='text' maxLength={20} name='username' />

        <div className='form_info'>{isAlreadyExist ? "ALREADY EXIST" : ""}</div>
        <button className='form_button' type='submit'>GO TO CHAT</button>
      </form>


    </div>
  );
}

export default LoginPage;
