
import { Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage/LoginPage.jsx";
import ChatPage from "./ChatPage";
import PrivateRoute from "../routes/PrivateRoute";
import AuthProvider from "../routes/AuthProvider";




function Main() {

    return (
        <div className="Main">
            <AuthProvider>
                <Routes>
                    <Route path="/" element={
                        <PrivateRoute>
                            <ChatPage />
                        </PrivateRoute>
                    } />

                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default Main;